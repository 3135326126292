import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isFree } from '@prerender/billing-shared';

import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';

import { getContent } from '../actions/pageActions';

const META_TAGS = {
  title: 'Product Portal - Prerender.io',
};
const cid = 'FeatureRequestPage';

const ProductPortalPage = ({ cm, language, getContent: doGetContent }) => {
  useEffect(() => {
    if (!cm) {
      doGetContent({ component: cid, lang: language });
    }
  }, [doGetContent, language, cm]);

  if (!cm) {
    return null;
  }
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <iframe
        style={{ width: '100%', height: '100%' }}
        id="feature-request-page-iframe"
        title="productboard-product-portal"
        src={cm.iframe.src}
      />
    </AdminTemplate>
  );
};

const mapStateToProps = (state) => ({
  cm: state.page.contentData[cid],
  language: state.page.language,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getContent,
    },
    dispatch
  );

function isAuthorized({ chargebeePlanId }) {
  return !isFree(chargebeePlanId);
}

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(ProductPortalPage, isAuthorized));
