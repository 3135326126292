import React from 'react';
import { Flex, Typography, Card, Divider, Button, Skeleton, Tooltip } from 'antd';
import { InfoCircleOutlined, SyncOutlined, MobileOutlined, DesktopOutlined, FlagOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { removeTrailingHttps } from '../../../assets/lib';
import { getAgeString } from '../../../assets/time';
import { useIsMobileView } from '../../../hooks/useIsMobileView';
import { UserAgentLogo } from '../../UserAgents';

const { Paragraph, Text } = Typography;

import { useGetCachedPagesQuery, useGetCrawlStatsQuery, useGetDashboardStatsQuery } from '../api/dashboardLegacySlice';

function TableLinkForCrawl({ item }) {
  return (
    <>
      <Flex justify={'space-between'}>
        <Flex gap={10} style={{ overflow: 'hidden' }}>
          <Text style={{ color: '#8C9EB0' }}>
            <UserAgentLogo userAgent={item.user_agent} />
          </Text>
          <Text ellipsis={true} style={{ color: '#415260', overflow: 'hidden' }}>
            {removeTrailingHttps(item.url)}
          </Text>
        </Flex>
        <Flex>
          <Text ellipsis={true}>
            <small>{getAgeString(new Date(item.time))}</small>
          </Text>
        </Flex>
      </Flex>
      <Divider style={{ margin: '12px 0', borderWidth: '2px' }} />
    </>
  );
}

function TableLinkForCache({ page }) {
  return (
    <>
      <Flex justify={'space-between'}>
        <Flex gap={10} style={{ overflow: 'hidden' }}>
          <Text style={{ color: '#8C9EB0' }}>
            {page.adaptive_type === 'desktop' && <DesktopOutlined />}
            {page.adaptive_type !== 'desktop' && <MobileOutlined />}
          </Text>
          <Text ellipsis={true} style={{ color: '#415260', overflow: 'hidden' }}>
            {removeTrailingHttps(page.url)}
          </Text>
        </Flex>
        <Flex>
          <Text ellipsis={true}>
            <small>{getAgeString(new Date(page.last_refresh), 'ago', 'short', true)}</small>
          </Text>
        </Flex>
      </Flex>
      <Divider style={{ margin: '12px 0', borderWidth: '2px' }} />
    </>
  );
}

export function LatestVisitsAndPagesInfo() {
  const { data: cachedPagesData, isFetching: isFetchingPages } = useGetCachedPagesQuery();
  const { data: crawlStatsData, isFetching: isFetchingCrawl } = useGetCrawlStatsQuery();
  const { data: dashboardStatsData, isFetching: isFetchingStats } = useGetDashboardStatsQuery();

  const isMobile = useIsMobileView();

  if (isFetchingPages || isFetchingCrawl || isFetchingStats) {
    return (
      <Flex justify={'space-between'}>
        <Card bordered={false} style={{ width: '28%' }}>
          <Skeleton />
        </Card>
        <Card bordered={false} style={{ width: '70%' }}>
          <Skeleton />
        </Card>
      </Flex>
    );
  }

  return (
    <Flex justify={'space-between'} vertical={isMobile} gap={isMobile ? 10 : 0}>
      <Card bordered={false} style={{ width: !isMobile ? '28%' : '100%' }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <Paragraph strong style={{ marginBottom: '0' }}>
            Last Crawler Visits
          </Paragraph>
          <Flex gap="small" align={'center'}>
            <Tooltip arrow={false} title="Crawlers requests served by our CDN">
              <InfoCircleOutlined />
            </Tooltip>

            <Link to="/crawl-stats">
              <Button size={'small'}>Show all</Button>
            </Link>
          </Flex>
        </Flex>

        <Flex vertical={true} justify={'space-between'}>
          {crawlStatsData?.slice(0, 6).map((d) => {
            return <TableLinkForCrawl key={d.url} item={d} />;
          })}

          {crawlStatsData?.length === 0 && (
            <Paragraph italic style={{ marginBottom: '0' }}>
              No crawler visits recorded yet.
            </Paragraph>
          )}
        </Flex>

        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px', marginTop: '20px' }}>
          <Paragraph strong style={{ marginBottom: '0' }}>
            Scheduled Rendering
          </Paragraph>
          <Flex gap="small" align={'center'}>
            <Tooltip arrow={false} title="Pages prompted for re-caching">
              <InfoCircleOutlined />
            </Tooltip>
            <Link to="/recache-queue">
              <Button size={'small'}>Show all</Button>
            </Link>
          </Flex>
        </Flex>

        <Flex vertical={true} justify={'space-between'}>
          <Flex justify={'space-between'}>
            <Flex gap={10}>
              <Text style={{ color: '#8C9EB0' }}>
                <SyncOutlined />
              </Text>
              <Text style={{ color: '#415260' }}>Automated Queue</Text>
            </Flex>
            <Flex>
              <Text>{dashboardStatsData?.result?.recacheQueueSize ?? '-'} pages</Text>
            </Flex>
          </Flex>
          <Divider style={{ margin: '12px 0', borderWidth: '2px' }} />
          <Flex justify={'space-between'}>
            <Flex gap={10}>
              <Text style={{ color: '#8C9EB0' }}>
                <FlagOutlined />
              </Text>
              <Text style={{ color: '#415260' }}>Manual/API Queue</Text>
            </Flex>
            <Flex>
              <Text>{dashboardStatsData?.result?.priorityQueueSize ?? '-'} pages</Text>
            </Flex>
          </Flex>
          <Divider style={{ margin: '12px 0', borderWidth: '2px' }} />
        </Flex>
      </Card>
      <Card bordered={false} style={{ width: !isMobile ? '70%' : '100%' }}>
        <Flex justify={'space-between'} align={'center'} style={{ marginBottom: '15px' }}>
          <Paragraph strong style={{ marginBottom: '0' }}>
            Recently Cached Pages
          </Paragraph>
          <Flex gap="small" wrap="wrap" align={'center'}>
            {/* <Link to="/cache">
              <PlusOutlined /> Add
            </Link> */}
            <Link to="/cache">
              <Button size={'small'}>Show all</Button>
            </Link>
          </Flex>
        </Flex>

        <Flex vertical={true} justify={'space-between'}>
          {cachedPagesData?.slice(0, 9).map((d) => (
            <TableLinkForCache key={d.id} page={d} />
          ))}

          {cachedPagesData?.length === 0 && (
            <Paragraph italic style={{ marginBottom: '0' }}>
              No pages has been cached recently.
            </Paragraph>
          )}
        </Flex>
      </Card>
    </Flex>
  );
}
