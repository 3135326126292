import React, { useMemo } from 'react';
import { Alert, Divider, Input, Form, theme } from 'antd';
import { getLowerTierRoles } from './appUserRoles';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';
import { RoleSelect } from '../../components/RoleSelect';

function InvitationForm({ onSubmit, invitation, selectRoles = false, isLoading = false, userRole }) {
  const [form] = Form.useForm();
  const { token } = theme.useToken();

  const visibleRoles = useMemo(() => {
    if (selectRoles) {
      return getLowerTierRoles(userRole);
    }
    return [];
  }, [userRole, selectRoles]);

  const onFinish = (values) => {
    onSubmit(values);
    form.resetFields();
  };

  return (
    <>
      <div style={{ marginBottom: token.marginMD }}>
        {/* Non keycloak case where we now the invitation link */}
        {invitation.email && <Alert type="success" message="Invitation Sent" />}
        {invitation.error && <Alert type="error" message={invitation.error} />}
      </div>

      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        initialValues={{ role: !!visibleRoles.length ? visibleRoles[0].value : null }}
      >
        <Form.Item
          label="Email address"
          name="email"
          rules={[
            {
              type: 'email',
              required: true,
              message: 'Please input a valid email address',
            },
          ]}
        >
          <Input name="email" />
        </Form.Item>
        <Form.Item
          label="Full name"
          name="fullName"
          rules={[
            {
              type: 'text',
            },
          ]}
        >
          <Input name="fullName" />
        </Form.Item>
        {selectRoles && (
          <Form.Item label="User role" name="role" rules={[{ required: true, message: 'Please select a user role' }]}>
            <RoleSelect
              visibleRoles={visibleRoles}
              value={form.getFieldValue('role')}
              onChange={(value) => form.setFieldValue('role', value)}
            />
          </Form.Item>
        )}
        <Divider />
        <Form.Item>
          <ButtonWithRole disabledFor={[USER_ROLES.GUEST]} type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </ButtonWithRole>
        </Form.Item>
      </Form>
    </>
  );
}

export default InvitationForm;
