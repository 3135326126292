import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Flex, Typography, Button } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import NavigationButtons from '../../NavigationButtons';
import Integrations from './Integrations';
import { InviteTeamMember } from '../../../../teams';
import SpinningWheel from '../../../../../components/SpinningWheel';
import { useGetDocumentationByIntegrationMethodQuery, useGetDomainQuery } from '../../../api/integrationWizardSlice';
import './documentation.css';
import { clearIntegrationResult, validateIntegration } from '../../../../integration/redux/IntegrationActions';
import { usePrerenderUser } from '../../../../../hooks/usePrerenderUser';
import { useEvent } from '../../../../events/hooks/useEvent';
import { useLoadDomainTechnologies } from '../../../hooks/useLoadDomainTechnologies';
import CurrentToken from '../../../../SecurityAndAccess/PrerenderToken/CurrentToken';

const { Text, Paragraph, Title } = Typography;

const cardStyle = {
  body: { background: '#FFFFFF', border: '1px solid #D9D9D9', borderRadius: '6px', padding: 12 },
};

const SuggestedIntegrationStep = ({ onGoBack, onGoNext, goToStart }) => {
  const dispatch = useDispatch();
  const onboardingData = useSelector((state) => state.onboarding);

  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();
  const [method, setMethod] = useState(null);
  const navigate = useNavigate();

  const {
    currentData: data,
    isFetching,
    isError,
  } = useGetDomainQuery('integration-step', { refetchOnMountOrArgChange: true });
  const { domainStats, isLoading: domainStatsLoading } = useLoadDomainTechnologies(data?.domain, goToStart);
  const {
    data: docData,
    isFetching: docLoading,
    error,
  } = useGetDocumentationByIntegrationMethodQuery(method, { skip: !method });

  useEffect(() => {
    if (isError || (!data?.domain && !isFetching)) {
      goToStart();
    }
  }, [isError, goToStart, data?.domain, isFetching]);

  const handleVerification = useCallback(() => {
    dispatch(validateIntegration(data.domain.startsWith('http') ? data.domain : `https://${data.domain}`));
  }, [data?.domain]);

  const handleEditDomain = () => {
    goToStart();
    track('Integration Wizard: Edit Domain Clicked', {
      subscription_plan: prerenderUser.chargebeePlanId,
    });
  };

  useEffect(() => {
    if (onboardingData.validationResult?.working) {
      onGoNext();
      dispatch(clearIntegrationResult());
      track('Verification Completed', {
        integration_method: method,
        subscription_plan: prerenderUser.chargebeePlanId,
        is_suggested_method: domainStats?.technologies?.includes(method) || false,
      });
    } else if (onboardingData.validationResult?.working === false) {
      track('Verification Failed', {
        integration_method: method,
        subscription_plan: prerenderUser.chargebeePlanId,
        is_suggested_method: domainStats?.technologies?.includes(method) || false,
      });
      dispatch(clearIntegrationResult());
      navigate('/integration-wizard/suggested-integration/failed');
    }
  }, [onboardingData.validationResult?.working]);

  const suggestedIntegrations = useMemo(() => domainStats?.technologies || [], [domainStats?.technologies]);

  if (isFetching || domainStatsLoading) {
    return (
      <Flex vertical align="center" justify="center" style={{ flex: 1 }}>
        <SpinningWheel matchParent />
      </Flex>
    );
  }

  return (
    <Flex vertical gap={16} style={{ marginTop: 32, flex: 1 }}>
      <Flex style={{ flexGrow: 1, position: 'relative' }}>
        {onboardingData.inProgress && (
          <div
            style={{
              position: 'absolute',
              inset: 0,
              display: 'flex',
              background: 'rgba(255, 255, 255, 0.2)',
              backdropFilter: 'blur(2px)',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 1,
            }}
          >
            <SpinningWheel matchParent />
          </div>
        )}
        <div
          style={{
            width: 300,
            transition: 'width 0.3s ease',
            padding: '12px 24px',
            gap: 8,
            position: 'relative',
          }}
        >
          <div style={{ position: 'absolute', top: 12, bottom: 12, left: 24, right: 24, overflow: 'auto' }}>
            <Flex justify="space-between" align="center" style={{ marginBottom: 12 }}>
              <Text strong>
                <GlobalOutlined style={{ paddingRight: 8 }} />
                {data?.domain}
              </Text>
              <Button onClick={handleEditDomain} type="link">
                Change
              </Button>
            </Flex>
            <Card styles={cardStyle} style={{ marginBottom: 12 }}>
              <Paragraph strong style={{ textAlign: 'center' }}>
                Need help with integration?
              </Paragraph>
              <InviteTeamMember
                source="Suggested Integration Step"
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 12 }}
                selectRoles={true}
                userRole={prerenderUser.role}
              />
              <Button
                type="link"
                href="https://docs.prerender.io/docs/how-does-prerender-work"
                target="_blank"
                style={{ paddingTop: 8 }}
                onClick={() => {
                  track('How does Prerender work link clicked', {
                    integration_method: method,
                    subscription_plan: prerenderUser.chargebeePlanId,
                    is_suggested_method: domainStats?.technologies?.includes(method) || false,
                  });
                }}
              >
                How does Prerender work?
              </Button>
            </Card>
            <Card styles={cardStyle} style={{ marginBottom: 12 }}>
              <Paragraph strong style={{ textAlign: 'center' }}>
                Prerender token (API Token)
              </Paragraph>
              <CurrentToken showBanner={false} tokenStyle={{ fontSize: 16 }} />
            </Card>
            <Card styles={cardStyle}>
              <Paragraph strong style={{ textAlign: 'center' }}>
                Integration methods
              </Paragraph>
              <Integrations
                selectedMethod={method}
                setSelectedMethod={setMethod}
                suggestedIntegrations={suggestedIntegrations}
              />
            </Card>
          </div>
        </div>
        <div style={{ width: 2, background: 'grey', margin: '0 16px', borderRadius: '4px' }}></div>
        <Flex vertical gap={16} style={{ flex: 1 }}>
          <Title level={4} style={{ marginTop: 0, fontWeight: '500', textAlign: 'center' }}>
            Follow this guide below to integrate Prerender with {method}
          </Title>
          <Card
            style={{ flex: 1, display: 'flex' }}
            styles={{ body: { flex: 1, display: 'flex', position: 'relative' } }}
          >
            <div style={{ position: 'absolute', inset: 0, display: 'flex' }}>
              {docLoading && (
                <Flex style={{ flex: 1 }} justify="center" align="center">
                  <SpinningWheel matchParent />
                </Flex>
              )}
              {!docLoading && !error && docData?.html && (
                <div className="documentation" dangerouslySetInnerHTML={{ __html: docData?.html }}></div>
              )}
              {!docLoading && error && (
                <div
                  style={{
                    padding: '12px 24px',
                    color: 'red',
                  }}
                >
                  An unexpected error has occured while retrieving documentation for {method}. Please try again by
                  reloading the page.
                </div>
              )}
            </div>
          </Card>
        </Flex>
      </Flex>
      <div style={{ background: '#F5F5F5', padding: 16, marginTop: 8 }}>
        <NavigationButtons
          disabledNext={onboardingData.inProgress}
          disabledBack={onboardingData.inProgress}
          onGoBack={onGoBack}
          onGoNext={() => {
            handleVerification();
            track('Integration Wizard: Verification Started', {
              integration_method: method,
              subscription_plan: prerenderUser.chargebeePlanId,
            });
          }}
          nextBtnText="Verify Integration"
          showIcon
        />
      </div>
    </Flex>
  );
};

export default SuggestedIntegrationStep;
