// this is the main page header component

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Input, Select } from 'antd';

const OPTIONS = [
  { value: 'like', label: 'Contains' },
  { value: 'nlike', label: 'Does not contain' },
  { value: 'eq', label: 'Is equal to' },
  { value: 'neq', label: 'Is not equal to' },
  { value: 'sw', label: 'Starts with' },
  { value: 'ew', label: 'Ends with' },
];

class SearchBarV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id || '', // searchBar id in case one component is switching between multiple SearchBars
      initialValue: props.initialValue || '',
      term: props.initialValue || '',
    };
    this.timer = null;
  }

  static getDerivedStateFromProps(props, state) {
    return props.id !== state.id ? { id: props.id, initialValue: props.initialValue, term: props.initialValue } : null;
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  inputChanged(event) {
    const newTerm = event.target.value;
    const { onTypingEnd, onTypingStart } = this.props;
    clearTimeout(this.timer);
    if (onTypingStart) onTypingStart();
    this.timer = setTimeout(() => {
      if (onTypingEnd) onTypingEnd(newTerm);
    }, 700);
    this.setState({ term: newTerm });
  }

  keyPressed(event) {
    if (event.key === 'Enter') {
      const newTerm = event.target.value;
      const { onEnterPressed } = this.props;
      if (onEnterPressed) onEnterPressed(newTerm);
    }
  }

  onSearchConditionChange(event) {
    const { onSearchConditionChanged } = this.props;
    if (onSearchConditionChanged) onSearchConditionChanged(event);
  }

  render() {
    const { placeholder = this.props.placeholder || 'Search', disabled = false } = this.props;
    const { term } = this.state;
    const selectBefore = (
      <Select
        popupMatchSelectWidth={false}
        id="urlFilterSelector"
        defaultValue="like"
        onChange={(event) => this.onSearchConditionChange(event)}
        style={{ width: 130 }}
        options={OPTIONS}
      />
    );

    return (
      <div className="input-group input-group-flush input-group-merge input-group-reverse">
        <Input
          addonBefore={selectBefore}
          placeholder={placeholder}
          disabled={disabled}
          value={term}
          allowClear={true}
          onChange={(event) => this.inputChanged(event)}
          onKeyPress={(event) => this.keyPressed(event)}
        />
      </div>
    );
  }
}

function mapStateToProps() {
  return {};
}

// provide access to selected actions:
function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBarV2);
