import React, { useMemo, useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Button, Card, Space, Tooltip, Typography, Flex } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

import { Container } from '../../components/CssFrameworkComponents';
import requireAuth from '../../components/hocs/requireAuth';
import { Pagination } from '../../components/PaginationLib';
import { Alert as ErrorAlert } from '../../components/CssFrameworkComponents';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import AdminTemplate from '../../layout/AdminTemplate';
import { useEvent } from '../events/hooks/useEvent';

import {
  useAddSitemapMutation,
  useReCacheSitemapMutation,
  useGetSitemapsQuery,
  useEditSitemapMutation,
  useToggleAllSitemapsMutation,
  useDeleteSitemapMutation,
} from './api/sitemapApiSlice';
import ImportSitemapModal from './components/ImportSitemapModal';
import SitemapsListTable from './components/SitemapsListTable';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';

const META_TAGS = {
  title: 'Sitemaps - Prerender.io',
};
const { Text, Title } = Typography;

function SitemapListPage() {
  const [page, setPage] = useState(0);

  // Load sitemaps
  const { data: getSitemapsData, isFetching, isError, error, refetch } = useGetSitemapsQuery(page + 1);

  // Add new hook
  const [
    addNewSitemap,
    { isLoading: isAddNewSitemapLoading, isError: isAddNewSitemapError, reset: resetNewSitemapError },
  ] = useAddSitemapMutation();

  const [_reCacheSitemap, { isError: isReCacheSitemapError, reset: resetRecacheError }] = useReCacheSitemapMutation();

  // Edit hook
  const [editSitemap] = useEditSitemapMutation();

  // Toggle all hook
  const [toggleAllSitemaps, { isLoading: isToggleAllSitemapsLoading }] = useToggleAllSitemapsMutation();

  // Delete sitemap hook
  const [deleteSitemap, { isLoading: isDeleteSitemapLoading }] = useDeleteSitemapMutation();

  const [isImportModalVisible, setIsImportModalVisible] = useState(false);

  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  // Reset mutation after closing modal
  useEffect(() => {
    if (!isImportModalVisible) {
      resetNewSitemapError();
      resetRecacheError();
    }
  }, [isImportModalVisible]);

  // Delete sitemap
  const onDeleteSitemap = async (id) => {
    try {
      await deleteSitemap(id).unwrap();
      track('Sitemap List Engaged', {
        subscription_plan: prerenderUser.plan.name,
        smap_is_activated: false,
        smap_is_deactivated: false,
        smap_is_exported: false,
        smap_is_deleted: true,
        smap_num_activated: 0,
        smap_num_deactivated: 0,
      });
    } catch (deleteSitemapError) {
      console.error({ deleteSitemapError });
    }
  };

  // Toggle sitemaps (enabled/disabled)
  const sitemapToggled = async (item) => {
    try {
      await editSitemap({ id: item.id, enabled: !item.enabled }).unwrap();
      track('Sitemap List Engaged', {
        subscription_plan: prerenderUser.plan.name,
        smap_is_activated: item.enabled,
        smap_is_deactivated: !item.enabled,
        smap_is_exported: false,
        smap_is_deleted: false,
        smap_num_activated: item.enabled ? 1 : 0,
        smap_num_deactivated: !item.enabled ? 1 : 0,
      });
    } catch (editSitemapError) {
      console.error({ editSitemapError });
    }
  };

  const toggleAll = async (newState) => {
    try {
      await toggleAllSitemaps(newState).unwrap();
    } catch (toggleAllSitemapsError) {
      console.error({ toggleAllSitemapsError });
    }
  };

  // Import sitemap modal
  const closeImportModal = () => setIsImportModalVisible(false);

  const onImport = async ({ sitemapUrl, adaptiveType, revisitInterval }) => {
    if (isAddNewSitemapLoading) {
      return;
    }

    const importSpeed = 3600; // TODO: Add to import form

    try {
      await addNewSitemap({
        url: sitemapUrl,
        importSpeed,
        revisitInterval,
        adaptiveType,
      }).unwrap();
      closeImportModal();
      track('Sitemap Imported', {
        subscription_plan: prerenderUser.chargebeePlanId,
      });
    } catch (addNewSitemapError) {
      console.error({ addNewSitemapError });
    }
  };

  // Transform sitemaps from BE for display
  const columns = [
    'Status',
    'Sitemap URL',
    'Created At',
    'Revisit Interval',
    'Device',
    'Last Visited At',
    'Next Visit',
    'Origin',
    'Health',
  ];

  const [visibleItems, csvData] = useMemo(() => {
    const visibleItems = getSitemapsData?.data
      ? [...getSitemapsData.data]
          .sort((a, b) => b.id - a.id)
          .map((item) => {
            const intervalHours = parseInt(item.revisitInterval, 10) / 60 / 60;

            const next_visit = item.lastCheckedAt
              ? new Date(new Date().setTime(new Date(item.lastCheckedAt).getTime() + intervalHours * 60 * 60 * 1000))
                  .toISOString()
                  .substring(0, 10)
              : null;

            const health = item.isHealthy === null ? 'Unknown' : item.isHealthy === true ? 'Healthy' : 'Unhealthy';
            const internalText = intervalHours >= 48 ? `${intervalHours / 24}  days` : `${intervalHours} hours`;
            return {
              id: item.id,
              enabled: item.isEnabled,
              sitemap_url: item.url,
              created_at: item.createdAt?.substring(0, 10),
              interval: internalText,
              device_type: item.adaptiveType,
              last_checked_at: item.lastCheckedAt?.substring(0, 10),
              next_visit,
              type: item.type === 'user_uploaded' ? 'User upload' : 'Auto-discovered',
              isHealthy: item.isHealthy,
              adaptiveType: item.adaptiveType,
              health,
            };
          })
      : [];

    const csvData = [
      columns,
      ...visibleItems.map((item) => {
        return [
          item.enabled ? 'Enabled' : 'Disabled',
          item.sitemap_url,
          item.created_at,
          item.interval,
          item.device_type,
          item.last_checked_at,
          item.next_visit,
          item.type,
          item.health,
        ];
      }),
    ];

    return [visibleItems, csvData];
  }, [getSitemapsData]);

  const isLoading = isFetching || prerenderUser.inProgress || isDeleteSitemapLoading;

  const pagination = getSitemapsData
    ? {
        total: getSitemapsData.total,
        amt: getSitemapsData.data.length, // Showing now
        pages: getSitemapsData.pages - 1,
        page: page,
        amtpp: getSitemapsData.itemsPerPage,
      }
    : {
        total: 0,
        amt: 0,
        pages: 0,
        page: 0,
        amtpp: 0,
      };
  return (
    <AdminTemplate metaTags={META_TAGS}>
      <ImportSitemapModal
        open={isImportModalVisible}
        errorMsg={isAddNewSitemapError || isReCacheSitemapError ? 'Failed to import sitemap' : null}
        onClose={closeImportModal}
        onImport={onImport}
        confirmLoading={isAddNewSitemapLoading}
        mobileAdaptive={prerenderUser.mobileAdaptive}
      />

      {isError && <ErrorAlert modal={false} content={{ text: 'Failed to load sitemaps' }} />}

      <Flex vertical style={{ marginBottom: 32 }}>
        <Flex justify={'space-between'} align={'center'}>
          <Title level={2} style={{ marginBottom: 8 }}>
            Sitemaps
          </Title>
        </Flex>
        <Text type="secondary">
          You can increase your SEO reactivity, and reduce on-demand renderings by loading your sitemaps into Prerender,
          our system will fetch and render those pages to get them ready before a crawler requesting it.
        </Text>
      </Flex>

      <Container addClass={'p-0'} fluid>
        <Flex justify="space-between" align="center" style={{ marginBottom: 20 }}>
          <p>You have {visibleItems.length} imported sitemaps in total</p>
          <Flex gap={8}>
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              type="primary"
              key="add"
              onClick={() => setIsImportModalVisible(true)}
            >
              Import Sitemap
            </ButtonWithRole>
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              key="enable-all"
              type="primary"
              danger={false}
              onClick={() => toggleAll(true)}
              disabled={!visibleItems.length || isToggleAllSitemapsLoading}
            >
              Activate All
            </ButtonWithRole>
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              key="disable-all"
              type="primary"
              danger={true}
              onClick={() => toggleAll(false)}
              disabled={!visibleItems.length || isToggleAllSitemapsLoading}
            >
              Deactivate All
            </ButtonWithRole>
          </Flex>
        </Flex>
        <Card
          title="Sitemaps"
          extra={[
            <Space key="space" wrap>
              <Tooltip key="reload-tooptip" title="Refresh sitemap list">
                <Button
                  icon={<ReloadOutlined />}
                  onClick={() => {
                    refetch();
                  }}
                  disabled={isFetching}
                  style={{ marginRight: 8 }}
                />
              </Tooltip>
              <CSVLink key="csv-export" data={csvData} filename={'sitemap.csv'}>
                <Button
                  key="export"
                  disabled={!visibleItems.length || isToggleAllSitemapsLoading}
                  onClick={() => {
                    track('Sitemap List Engaged', {
                      subscription_plan: prerenderUser.plan.name,
                      smap_is_activated: false,
                      smap_is_deactivated: false,
                      smap_is_exported: true,
                      smap_is_deleted: false,
                      smap_num_activated: 0,
                      smap_num_deactivated: 0,
                    });
                  }}
                >
                  Export
                </Button>
              </CSVLink>
            </Space>,
          ]}
        >
          <SitemapsListTable
            rows={visibleItems}
            isLoading={isLoading}
            onStatusToggle={sitemapToggled}
            onDeleteClick={onDeleteSitemap}
            mobileAdaptive={prerenderUser.mobileAdaptive}
          />
          <Pagination onPageChange={(action) => setPage(action.payload)} stats={pagination} />
        </Card>
      </Container>
    </AdminTemplate>
  );
}

export default requireAuth(SitemapListPage);
