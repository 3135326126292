import React, { useEffect, useState, useMemo } from 'react';
import * as ms from 'ms';
import { Button, Card, Space, Tooltip, Flex, Typography } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { Alert as ErrorAlert } from '../../components/CssFrameworkComponents';
import { useEvent } from '../events/hooks/useEvent';

import {
  useGetDomain404ChecksQuery,
  useAddDomain404CheckMutation,
  useEditDomain404CheckMutation,
  useToggleAllDomain404ChecksMutation,
  useDeleteDomain404CheckMutation,
} from './api/domain404CheckerApiSlice';
import DomainModal from './components/DomainModal';
import DomainsTable from './components/DomainsTable';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import ButtonWithRole from '../../components/ButtonWithRole';
import USER_ROLES from '../auth/userRoles';

const { Text, Title } = Typography;

const META_TAGS = {
  title: '404 Checker - Prerender.io',
};
const getAddErrorMessage = (error) => {
  if (error?.status === 409) {
    return 'Domain already added to the list';
  }

  if (error?.status === 400) {
    return 'Domain cannot be reached';
  }

  return 'Failed to add domain';
};

function Domain404CheckerPage() {
  const [pollingInterval, setPollingInterval] = useState(null);

  // Load checks
  const {
    data: getDomain404ChecksResult,
    isFetching,
    isError,
    error,
    refetch,
  } = useGetDomain404ChecksQuery(undefined, {
    pollingInterval,
  });

  useEffect(() => {
    const shouldPoll = getDomain404ChecksResult?.data
      ? [...getDomain404ChecksResult?.data]
          .sort((a, b) => b.id - a.id)
          .some((item) => {
            return !item.lastCheckedAt && item.isEnabled;
          })
      : [];

    if (shouldPoll) {
      setPollingInterval(3000);
    } else {
      setPollingInterval(null);
    }
  }, [getDomain404ChecksResult]);

  // Add new hook
  const [
    addNewCheck,
    { isLoading: isAddNewLoading, isError: isAddNewError, reset: resetNewError, error: addNewCheckError },
  ] = useAddDomain404CheckMutation();

  // Edit hook
  const [editCheck, { isLoading: isEditLoading, isError: isEditError, reset: resetEditError }] =
    useEditDomain404CheckMutation();

  // Toggle all hook
  const [toggleAllChecks, { isLoading: isToggleAllLoading }] = useToggleAllDomain404ChecksMutation();

  // Delete check hook
  const [deleteCheck, { isLoading: isDeleteCheckLoading }] = useDeleteDomain404CheckMutation();

  const [isAddEditModalVisible, setAddEditModalVisible] = useState(false);

  const prerenderUser = usePrerenderUser();
  const { track } = useEvent();

  // Reset mutation after closing modal
  useEffect(() => {
    if (!isAddEditModalVisible) {
      resetNewError();
      resetEditError();
    }
  }, [isAddEditModalVisible]);

  // Delete
  const onDeleteCheck = async (id) => {
    try {
      await deleteCheck(id).unwrap();
    } catch (deleteCheckError) {
      console.error({ deleteCheckError });
    }
  };

  // Toggle enable/disable (enabled/disabled)
  const onCheckToggled = async (item) => {
    try {
      await editCheck({ id: item.id, enabled: !item.isEnabled }).unwrap();
      track('404 page engagement', {
        is_activated: item.isEnabled,
      });
    } catch (editCheckError) {
      console.error({ editCheckError });
    }
  };

  const onToggleAll = async (newState) => {
    try {
      await toggleAllChecks(newState).unwrap();
    } catch (toggleAllChecksError) {
      console.error({ toggleAllChecksError });
    }
  };

  // Add/update modal
  const closeImportModal = () => setAddEditModalVisible(false);

  const onAddUpdate = async ({ url, revisitInterval, id }) => {
    if (isAddNewLoading || isEditLoading) {
      return;
    }

    try {
      if (!id) {
        await addNewCheck({ url, revisitInterval }).unwrap();
        track('New Domain Added');
      } else {
        await editCheck({ id, url, revisitInterval }).unwrap();
      }
      closeImportModal();
    } catch (addNewError) {
      console.error({ addNewError });
    }
  };

  // Transform from BE for display
  const columns = [
    'Enabled',
    'Domain',
    'Created At',
    'Check Interval',
    'Last Checked At',
    'Next Check',
    'Origin',
    'Domain reachable',
    '404 status returned',
  ];

  const visibleItems = useMemo(() => {
    const visibleItems = getDomain404ChecksResult?.data
      ? [...getDomain404ChecksResult?.data]
          .sort((a, b) => b.id - a.id)
          .map((item) => {
            const nextVisit = item.lastCheckedAt
              ? new Date(new Date().setTime(new Date(item.lastCheckedAt).getTime() + item.revisitInterval))
                  .toISOString()
                  .substring(0, 10)
              : item.isEnabled
              ? 'In a few minutes'
              : '-';

            return {
              ...item,
              type: item.type === 'user_uploaded' ? 'User upload' : 'Auto-discovered',
              revisitInterval: item?.revisitInterval ? ms(item.revisitInterval, { long: true }) : '-',
              lastCheckedAt: item.lastCheckedAt ? item.lastCheckedAt?.substring(0, 10) : '-',
              createdAt: item.createdAt?.substring(0, 10),
              nextVisit,
            };
          })
      : [];

    return visibleItems;
  }, [getDomain404ChecksResult]);

  const isLoading = isFetching || prerenderUser.inProgress || isDeleteCheckLoading || isToggleAllLoading;

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <DomainModal
        open={isAddEditModalVisible !== false}
        errorMsg={isAddNewError ? getAddErrorMessage(addNewCheckError) : isEditError ? 'Failed to edit domain' : null}
        onClose={closeImportModal}
        onSubmit={onAddUpdate}
        confirmLoading={isAddNewLoading}
        existing={isAddEditModalVisible !== true ? isAddEditModalVisible : null}
      />

      {isError && <ErrorAlert modal={false} content={{ text: 'Failed to load domain 404 checks' }} />}

      <Flex vertical style={{ marginBottom: 32 }}>
        <Flex justify={'space-between'} align={'center'}>
          <Title level={2} style={{ marginBottom: 8 }}>
            404 Checker
          </Title>
          <Flex gap={8}>
            <Tooltip key="reload-tooptip" title="Refresh list">
              <Button
                key="reload"
                icon={<ReloadOutlined />}
                onClick={() => {
                  refetch();
                }}
                disabled={isFetching}
              />
            </Tooltip>

            <a key="docs" target="_blank" href="https://docs.prerender.io/docs/404-checker">
              <Button>How to fix unhealthy 404 checks</Button>
            </a>

            <Tooltip title="Please add your domain names here, and select the frequency at which we should perform this check.">
              <ButtonWithRole
                disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                type="primary"
                key="add"
                onClick={() => setAddEditModalVisible(true)}
              >
                Add domain
              </ButtonWithRole>
            </Tooltip>
          </Flex>
        </Flex>
        <Text type="secondary">
          This tool checks your domains to ensure your 404 error pages respond with the correct status code to avoid
          caching and serving them as 200 status codes.
        </Text>
      </Flex>

      <Card
        title="Domains"
        extra={[
          <Space wrap key="space">
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              key="enable-all"
              type="primary"
              danger={false}
              onClick={() => onToggleAll(true)}
              disabled={!visibleItems.length || isLoading}
            >
              Activate All
            </ButtonWithRole>
            <ButtonWithRole
              disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
              key="disable-all"
              type="primary"
              danger={true}
              onClick={() => onToggleAll(false)}
              disabled={!visibleItems.length || isLoading}
            >
              Deactivate All
            </ButtonWithRole>
          </Space>,
        ]}
      >
        <DomainsTable
          isLoading={isLoading}
          rows={visibleItems}
          onEnabledToggle={onCheckToggled}
          onEditClick={setAddEditModalVisible}
          onDeleteClick={onDeleteCheck}
        />
      </Card>
    </AdminTemplate>
  );
}

export default requireAuth(Domain404CheckerPage);
