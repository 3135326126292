import React from 'react';
import { Row, Col, Statistic, Card, Skeleton, Flex, Typography, Tooltip, theme } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Link, useParams } from 'react-router-dom';

import { formatCacheAge } from './utils/getTimeLabel';

const TitleWithToolTip = ({ title, tooltip }) => (
  <Flex justify="space-between" align="center">
    <Typography.Text type="secondary">{title}</Typography.Text>
    <Tooltip title={tooltip}>
      <QuestionCircleOutlined />
    </Tooltip>
  </Flex>
);

function DomainStats({ isLoading, uniquePages, averageCacheMinutes, firstSeenAt, pagesNotCrawled }) {
  const { token } = theme.useToken();
  const { domain } = useParams();

  const averageCacheAge = React.useMemo(() => formatCacheAge(averageCacheMinutes), [averageCacheMinutes]);

  return (
    <Row gutter={[token.marginMD, token.marginMD]}>
      <Col xs={12} xl={4}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Unique Pages" value={uniquePages || '-'} />
          </Skeleton>
        </Card>
      </Col>
      <Col xs={12} xl={6}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic title="Average Cache Age" value={averageCacheAge} />
          </Skeleton>
        </Card>
      </Col>
      <Col xs={12} xl={8}>
        <Card bordered size="small">
          <Skeleton loading={isLoading} active>
            <Statistic
              title="Fist Seen At"
              value={firstSeenAt}
              formatter={(date) => (date ? new Date(date).toLocaleString() : '-')}
            />
          </Skeleton>
        </Card>
      </Col>
      <Col xs={12} xl={6}>
        <Card
          bordered
          size="small"
          actions={[
            <Link to="/cache" state={{ domain, notCrawledPagesFilter: true }}>
              View all pages
            </Link>,
          ]}
        >
          <Skeleton loading={isLoading} active>
            <Statistic
              title={
                <TitleWithToolTip
                  title="Pages not crawled"
                  tooltip="Pages that have not been crawled in the past 30 days"
                />
              }
              value={pagesNotCrawled}
              suffix="pages"
            />
          </Skeleton>
        </Card>
      </Col>
    </Row>
  );
}

export default DomainStats;
