import { LineChartOutlined } from '@ant-design/icons';
import { Button, Space, Tooltip, Alert, Flex, Typography } from 'antd';
import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import crawlerActivitySearch from '../actions/crawlerActivityActions';
import { crawlerStatsOverviewSearch, crawlerStatsResponseTimeSearch } from '../actions/crawlerStatsActions';
import { closeModal, getContent, openModal } from '../actions/pageActions';
import { getChartData } from '../assets/charts';
import { Card } from '../components/CssFrameworkComponents';
import ConfirmationModal from '../components/ConfirmationModal';
import ErrorLoader from '../components/ErrorLoader';
import requireAuth from '../components/hocs/requireAuth';
import AdminTemplate from '../layout/AdminTemplate';
import { useEvent } from '../features/events/hooks/useEvent';
import { CrawlerActivityTable } from '../features/crawler-activity/CrawlerActivityTable';
import { BlurringOverlay } from '../components/BlurringOverlay/BlurringOverlay';
import { getDomains } from '../actions/domainActions';
import { Pagination } from '../components/PaginationLib';
import { isFreePlan } from '../chargebee/chargebee';
import { getPrerenderUser } from '../actions/prerenderUserActions';
import ExportAnalyticsModal from '../features/crawler-activity/ExportAnalyticsModal';
import { cdnAnalyticsApiSlice } from '../features/api/cdnAnalyticsApiSlice';
import SearchPanel from '../components/SearchPanel';
import { DomainSearchDropdown } from '../components/DomainSearchDropdown';

const { Text, Paragraph, Title } = Typography;

const META_TAGS = {
  title: 'Crawler Visits - Prerender.io',
};
const cid = 'CrawlerActivityPage';
const URL_LIST_SEARCH_EVENT_NAME = 'CDN Analytics URL List Searched';
const PAGE_SIZE = 50;

function withRouter(Component) {
  const Wrapper = (props) => {
    const location = useLocation();

    return <Component {...props} routerState={location.state} />;
  };

  return Wrapper;
}

class CrawlerActivityPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartView: 'volume',
      customMessage: null,
      querySearchInProgress: false,
      initialSearchQuery: props?.crawlerActivity?.params?.query,
      searchBarId: new Date().getTime(),
    };
  }

  loadDomains(query = null) {
    const { getDomains: getDomains } = this.props;
    getDomains(query);
  }

  componentDidMount() {
    const { cm, language, getContent: doGetContent, prerenderUser, routerState } = this.props;
    if (!cm) doGetContent({ groups: ['crawlerActivity', 'default'], lang: language });

    const paidPlan = !isFreePlan(prerenderUser.plan);

    const searchParams = { page: 0, pageSize: PAGE_SIZE, sort: 'time', sortDirection: 'DESC' };

    if (paidPlan && this.props.routerState?.domain) searchParams.domain = routerState?.domain;
    if (this.props.routerState?.userAgent) searchParams.userAgent = routerState?.userAgent;

    this.performSearch(searchParams);
    this.loadDomains();
  }

  performQuerySearch(query) {
    const { track } = useEvent();
    this.setState({ querySearchInProgress: false });
    if (query.length === 0 || query.length > 2) {
      this.performSearch({ ...this.props.crawlerActivity.params, page: 0, query });
      if (query.length > 2) {
        track(URL_LIST_SEARCH_EVENT_NAME, {
          search_string: query,
          subscription_plan: this.props.prerenderUser.chargebeePlanId,
        });
      }
    }
  }

  performQuerySearchAnyway(query) {
    const { track } = useEvent();
    this.setState({ querySearchInProgress: false });
    this.performSearch({ ...this.props.crawlerActivity.params, page: 0, query });
    track(URL_LIST_SEARCH_EVENT_NAME, {
      search_string: query,
      subscription_plan: this.props.prerenderUser.chargebeePlanId,
    });
  }

  onSearchConditionChanged(queryCondition) {
    this.setState({ querySearchInProgress: false });
    if (this.props.crawlerActivity.params.query.length === 0 || this.props.crawlerActivity.params.query.length > 2) {
      this.performSearch({ ...this.props.crawlerActivity.params, page: 0, queryCondition });
    }
  }

  clearFilters() {
    this.setState({ querySearchInProgress: false, searchBarId: new Date().getTime() });
    this.performSearch({
      domain: this.props.crawlerActivity.params.domain,
      page: 0,
      pageSize: PAGE_SIZE,
      sort: 'date',
    });
  }

  performSearch(params, options = { onlyMainSearch: false }) {
    const {
      crawlerActivitySearch: doCrawlerActivitySearch,
      crawlerStatsOverviewSearch: doCrawlerStatsOverviewSearch,
      crawlerStatsResponseTimeSearch: doCrawlerStatsResponseTimeSearch,
      crawlerActivity,
      crawlerStats,
    } = this.props;
    if (!crawlerActivity.inProgress) doCrawlerActivitySearch('all', params);
    if (!crawlerStats.inProgress && !options.onlyMainSearch) {
      doCrawlerStatsOverviewSearch(params);
      doCrawlerStatsResponseTimeSearch(params);
    }
  }

  reloadData() {
    const { getPrerenderUser, crawlerActivity } = this.props;
    getPrerenderUser();
    this.performSearch({ ...crawlerActivity.params });
  }

  performDomainSearch(domain) {
    const { crawlerActivity } = this.props;
    this.performSearch({ ...crawlerActivity.params, domain });
  }

  static mapExportAnalyticsResponseStatusToUserText(status) {
    switch (status) {
      case 'queued':
        return 'We have scheduled your export. You will get a download link in your email once the export is ready.';
      case 'already exists':
        return "You already have an export in progress. We'll notify you via email when that export finishes.";
      default:
        return "Something bad has happened. We're looking into it...";
    }
  }

  didReceiveAction({ action, payload }) {
    const {
      crawlerActivity,
      openModal: doOpenModal,
      closeModal: doCloseModal,
      exportAnalytics: doExportAnalytics,
      exportRequestsPerDomain: doExportRequestsPerDomain,
    } = this.props;

    switch (action) {
      case 'openExportModal':
        doOpenModal(
          // not sure if this ID needs to be unique so that it doesn't conflict with CSV export from CachedPagesPage
          'exportAnalyticsModal',
          <ExportAnalyticsModal onEvent={(onEventAction) => this.didReceiveAction(onEventAction)} />
        );
        break;
      case 'openExportSuccessModal':
        doOpenModal(
          'exportAnalyticsSuccessModal',
          <ConfirmationModal
            title={'Export Render History'}
            text={CrawlerActivityPage.mapExportAnalyticsResponseStatusToUserText(payload.status)}
            options={[{ action: 'cancel', text: 'Close' }]}
            onAction={(_action) => {}}
          />
        );
        break;
      case 'openExportErrorModal':
        doOpenModal(
          'exportModal',
          <ConfirmationModal
            title={'Export Render History'}
            text={"Something bad has happened. We're looking into it..."}
            options={[{ action: 'cancel', text: 'Close' }]}
            onAction={(_action) => {}}
          />
        );
        break;
      case 'exportAnalytics':
        doCloseModal();
        doExportAnalytics({ interval: payload.interval, fileType: payload.fileType, ...crawlerActivity.params })
          .then(({ data, error }) => {
            this.didReceiveAction(
              data
                ? { action: 'openExportSuccessModal', payload: data }
                : { action: 'openExportErrorModal', payload: error }
            );
          })
          .catch((e) => console.error('Unexpected error:', e));
        break;
      case 'exportRequestPerDomain':
        doCloseModal();
        const params = {
          from: payload.dateRange[0].format('YYYY-MM-DD'),
          to: payload.dateRange[1].format('YYYY-MM-DD'),
        };
        doExportRequestsPerDomain(params)
          .then(({ data, error }) => {
            this.didReceiveAction(
              data
                ? { action: 'openExportSuccessModal', payload: data }
                : { action: 'openExportErrorModal', payload: error }
            );
          })
          .catch((e) => console.error('Unexpected error:', e))
          .then(() => {
            const { track } = useEvent();
            track('Export Crawler Requests Per Domain', params);
          });
        break;
      case 'modalCancelled':
        doCloseModal();
        break;
      case 'changePage':
        this.performSearch({ ...crawlerActivity.params, page: payload }, { onlyMainSearch: true });
        break;
      case 'updateSearchParams': {
        const {
          statusCodeEq,
          statusCodeLow,
          statusCodeHigh,
          responseTimeLow,
          responseTimeHigh,
          timedout,
          userAgent,
          adaptive_type,
          renderedTimeLow,
          renderedTimeHigh,
          userAgentCaseSensitiveSearch,
          q,
          qCondition,
          sort,
          sortDirection,
          cacheHit,
        } = payload;
        this.performSearch({
          ...crawlerActivity.params,
          statusCodeEq,
          statusCodeLow,
          statusCodeHigh,
          responseTimeLow,
          responseTimeHigh,
          timedout,
          userAgent,
          userAgentCaseSensitiveSearch,
          adaptive_type,
          renderedTimeLow,
          renderedTimeHigh,
          q,
          qCondition,
          sort,
          sortDirection,
          page: 0,
          cacheHit,
        });

        break;
      }
      default:
        console.warn(`Unkown action: ${action}`); // TODO: handle error
    }
  }

  drawBarChart(id, title, labels = ['label 1', 'label 2']) {
    const { crawlerStats, isMobile } = this.props;
    const ref = `${id}ChartRef`;
    const source = id === 'volume' ? 'overview' : 'responseTime';
    const yAxes =
      source === 'responseTime'
        ? {
            type: 'logarithmic',
            ticks: {
              stepSize: 1,
              beginAtZero: true,
              autoSkipPadding: 40,
              callback(value) {
                return value.length ? Number(value).toLocaleString() : value;
              },
            },
            grid: { color: '#f0f0f0', drawOnChartArea: false },
          }
        : {
            ticks: { stepSize: 1, beginAtZero: true, autoSkipPadding: 20 },
            grid: { color: '#f0f0f0', drawOnChartArea: false },
          };
    return (
      <div className="px-3 pb-3">
        <div className="mb-2">
          <Paragraph strong>{title}</Paragraph>
          <Text type="secondary">
            A HIT represents a requested page that was served from our cache. A MISS represents a requested page that
            was rendered on the fly, served, and only cached if it responded with a 200 status code.
          </Text>
        </div>
        <div className="ml-1" style={{ height: '250px' }}>
          <Bar
            ref={this[ref]}
            data={getChartData('bar', crawlerStats[source].params.timePeriod, [
              { source: crawlerStats[source].requests.cacheHits, label: labels[0], color: 'success' },
              { source: crawlerStats[source].requests.cacheMisses, label: labels[1], color: 'warning' },
            ])}
            options={{
              animation: false,
              plugins: {
                legend: {
                  display: true,
                  position: isMobile ? 'bottom' : 'right',
                  labels: { boxWidth: 10 },
                },
              },
              maintainAspectRatio: false,
              scales: {
                y: yAxes,
                x: { grid: { color: '#f0f0f0' } },
              },
            }}
          />
        </div>
      </div>
    );
  }

  render() {
    const { cm, apiErrTxt, lastErrorCode, crawlerActivity, prerenderUser, modalVisible, domains } = this.props;
    const { customMessage, chartView } = this.state;
    const { page, pageSize, domain } = crawlerActivity.params;

    const paidPlan = !isFreePlan(prerenderUser.plan);

    const renderSearchPanel = () => {
      const { domains, initialSearchQuery } = this.props;
      return (
        <SearchPanel
          setState={() => this.setState()}
          onChangeTable={(change) => this.didReceiveAction(change)}
          domains={domains}
          clearFilters={() => this.clearFilters()}
          onUpdateTable={() => this.reloadData()}
          searchBarId={this.state.searchBarId}
          initialSearchQuery={initialSearchQuery}
          performQuerySearch={(query) => this.performQuerySearch(query)}
          performQuerySearchAnyway={(query) => this.performQuerySearchAnyway(query)}
          onSearchConditionChanged={(query) => this.onSearchConditionChanged(query)}
        />
      );
    };

    if (!cm) return null;
    return (
      <AdminTemplate metaTags={META_TAGS}>
        <Flex vertical style={{ marginBottom: 32 }}>
          <Flex justify={'space-between'} align={'center'}>
            <Title level={2} style={{ marginBottom: 8 }}>
              CDN Analytics
            </Title>
            <Space>
              {domains.domains.length > 1 && paidPlan && (
                <DomainSearchDropdown
                  domains={domains.domains}
                  performDomainSearch={(domain) => this.performDomainSearch(domain)}
                  selectedDomain={domain}
                />
              )}
              <Link to="/insight" key="insight">
                <Tooltip title="Get insight into longterm CDN deliveries for your account" placement="left">
                  <Button key="insight-btn" size="middle" icon={<LineChartOutlined />}>
                    Show History
                  </Button>
                </Tooltip>
              </Link>
            </Space>
          </Flex>
          <Text type="secondary">Recent crawler requests handled by our Delivery CDN</Text>
        </Flex>
        <div>
          <ErrorLoader code={modalVisible ? '' : lastErrorCode} custom={customMessage} scrollTop>
            <div className="mb-4">
              <Alert
                className="col-12"
                showIcon
                type="error"
                message={apiErrTxt[lastErrorCode] ? apiErrTxt[lastErrorCode] : customMessage?.text}
              />
            </div>
          </ErrorLoader>
          <div className="row">
            <Card width="col-12">
              <div className="card-header pl-3">
                <ul className="nav nav-tabs nav-tabs-sm card-header-tabs">
                  {cm.card.tabs.map((tab) => (
                    <li key={`tab${tab.target}`} className="nav-item">
                      <a
                        href={`#${tab.target}`}
                        onClick={() => this.setState({ chartView: tab.target })}
                        className={`nav-link${chartView === tab.target ? ' active' : ''}`}
                        data-toggle="tab"
                      >
                        {tab.text}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="card-body">
                <BlurringOverlay
                  enabled={!prerenderUser.trackingCodeInstalled}
                  location="CDN Analyics page"
                  content="Complete Prerender Integration to see all crawlers visiting your pages"
                />
                <div className="row">
                  <div className="col-12 col-xl-11">
                    {chartView === 'volume' &&
                      this.drawBarChart('volume', cm.charts.volume.title, cm.charts.volume.labels)}
                    {chartView === 'responseTime' &&
                      this.drawBarChart('reponseTime', cm.charts.responseTime.title, cm.charts.responseTime.labels)}
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="row">
            <div className="col-12 ml-2 mb-3"></div>
            <Card width="col-12">
              <BlurringOverlay enabled={!prerenderUser.trackingCodeInstalled} />
              <CrawlerActivityTable
                renderSearchPanel={renderSearchPanel}
                dataSource={crawlerActivity}
                onChangeTable={(onEventAction) => this.didReceiveAction(onEventAction)}
                initialFilters={crawlerActivity.params}
              />
              <Pagination
                onPageChange={(action) => this.didReceiveAction(action)}
                stats={{
                  total: crawlerActivity.stats.total,
                  // total: query ? crawlerActivity.stats.total : crawlerActivity.params.pageSize,
                  amt: crawlerActivity.stats.amt,
                  pages:
                    crawlerActivity.stats.amt < crawlerActivity.params.pageSize
                      ? crawlerActivity.stats.pages
                      : page + 1,
                  page,
                  amtpp: pageSize,
                }}
              ></Pagination>
            </Card>
          </div>
        </div>
      </AdminTemplate>
    );
  }
}

function mapStateToProps(state) {
  return {
    cm: state.page.contentData[cid],
    isMobile: state.page.mobileDevice,
    editorCm: state.page.contentData.ActivitySearchEditor,
    ssrEntryUrl: state.page.ssrEntryUrl,
    lastErrorCode: state.page.lastErrorCode,
    apiErrTxt: state.page.contentData.apiError,
    language: state.page.language,
    crawlerStats: state.crawlerStats,
    crawlerActivity: state.crawlerActivity.all,
    prerenderUser: state.prerenderUser,
    modalVisible: state.page.modalVisible,
    domains: state.domains,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getContent,
      openModal,
      closeModal,
      crawlerStatsOverviewSearch,
      crawlerStatsResponseTimeSearch,
      crawlerActivitySearch,
      getDomains,
      getPrerenderUser,
      exportAnalytics: cdnAnalyticsApiSlice.endpoints.exportAnalytics.initiate,
      exportRequestsPerDomain: cdnAnalyticsApiSlice.endpoints.exportRequestPerDomain.initiate,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(requireAuth(withRouter(CrawlerActivityPage)));
