import { Select } from 'antd';

export const DomainSearchDropdown = ({ selectedDomain, domains, performDomainSearch }) => {
  return (
    <Select
      showSearch
      allowClear={true}
      style={{ width: 200 }}
      popupMatchSelectWidth={false}
      filterOption={(input, option) => (option?.domain ?? '').includes(input)}
      filterSort={(optionA, optionB) => (optionA?.domain ?? '').localeCompare(optionB?.domain ?? '')}
      placeholder="Domain"
      options={domains}
      fieldNames={{ label: 'domain', value: 'domain' }}
      onChange={performDomainSearch}
      value={selectedDomain}
    />
  );
};
