import { Steps, Flex } from 'antd';
import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import ImportSitemapsStep from './components/steps/ImportSitemapsStep';
import SuggestedIntegrationRouter from './components/steps/SuggestedIntegrationStep';
import ScanDomainStep from './components/steps/ScanDomainStep/ScanDomainStep';
import VerificationSuccessfulStep from './components/steps/VerificationSuccessfulStep';
import { useIntegrationWizardSteps } from './useIntegrationWizardSteps';
import { Navigate, Route, Routes } from 'react-router-dom';
import InviteTeamStep from './components/steps/InviteTeamStep/InviteTeamStep';
import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useEffect, useState } from 'react';
import SpinningWheel from '../../components/SpinningWheel';
import USER_ROLES from '../auth/userRoles';

const META_TAGS = {
  title: 'Integration Wizard - Prerender.io',
};

const stepConfig = {
  testGroupA: [
    { path: 'domain', title: 'Scan Domain', disabled: true },
    /*{ path: 'sitemap', title: 'Import Sitemaps', component: ImportSitemapsStep, disabled: true },*/
    { path: 'invite-team', title: 'Invite Team', disabled: true },
    { path: 'suggested-integration', title: 'Integrate', disabled: true },
    { path: 'verification', title: 'Start Rendering', disabled: true },
  ],
  testGroupB: [
    { path: 'invite-team', title: 'Invite team', disabled: true },
    { path: 'domain', title: 'Scan Domain', disabled: true },
    /*{ path: 'sitemap', title: 'Import Sitemaps', component: ImportSitemapsStep, disabled: true },*/
    { path: 'suggested-integration', title: 'Integrate', disabled: true },
    { path: 'verification', title: 'Start Rendering', disabled: true },
  ],
};

const IntegrationWizardPage = () => {
  const [testGroup, setTestGroup] = useState(null);
  const selectedConfig = stepConfig[testGroup];
  const { onGoBack, onGoNext, goToStart, currentStep, setCurrentStep } = useIntegrationWizardSteps(selectedConfig);
  const prerenderUser = usePrerenderUser();

  useEffect(() => {
    if (prerenderUser?.id && prerenderUser?.id % 2 === 0) {
      setTestGroup('testGroupA');
    } else {
      setTestGroup('testGroupB');
    }
  }, [prerenderUser?.id]);

  if (!selectedConfig) {
    return <SpinningWheel />;
  }

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Flex vertical gap="large" style={{ height: '100%' }}>
        <Steps current={currentStep} items={selectedConfig} onChange={setCurrentStep} />

        <Routes>
          <Route
            path="domain"
            element={<ScanDomainStep onGoNext={onGoNext} onGoBack={onGoBack} currentStep={currentStep} />}
          />
          <Route
            path="invite-team"
            element={
              <InviteTeamStep onGoBack={onGoBack} onGoNext={onGoNext} testGroup={testGroup} currentStep={currentStep} />
            }
          />
          <Route
            path="suggested-integration/*"
            element={<SuggestedIntegrationRouter onGoBack={onGoBack} onGoNext={onGoNext} goToStart={goToStart} />}
          />
          <Route path="verification" element={<VerificationSuccessfulStep onGoNext={onGoNext} />} />
          <Route path="" element={<Navigate to={selectedConfig[0].path} />}></Route>
        </Routes>
      </Flex>
    </AdminTemplate>
  );
};

const isAuthorized = ({ role }) => {
  return role !== USER_ROLES.GUEST;
};
export default requireAuth(IntegrationWizardPage, isAuthorized);
