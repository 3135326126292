import React from 'react';
import { Flex, Typography, Layout, Button, Dropdown, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import requireAuth from '../../components/hocs/requireAuth';
import AdminTemplate from '../../layout/AdminTemplate';
import { useEvent } from '../events/hooks/useEvent';

import { usePrerenderUser } from '../../hooks/usePrerenderUser';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import USER_ROLES from '../auth/userRoles';

const { Title, Text } = Typography;
const { Content } = Layout;

const META_TAGS = {
  title: 'Dashboard - Prerender.io',
};

import { DashboardUsageOverview } from './components/DashboardUsageOverview';
import { DeliveredToCrawlerCharts } from './components/DeliveredToCrawlerCharts';
import { DeliveredByStatusCharts } from './components/DeliveredByStatusCharts';
import { CrawlerVisitsCharts } from './components/CrawlerVisitsCharts';
import { LatestVisitsAndPagesInfo } from './components/LatestVisitsAndPagesInfo';
import HighlightedFeaturesBanners, { HighlightedFeatureBanner } from './components/HighlightedFeaturesBanners';

const items = [
  {
    label: 'Last 1 day',
    key: '1',
  },
  {
    label: 'Last 7 days',
    key: '7',
  },
  {
    label: 'Last 30 days',
    key: '30',
  },
];

function DashboardPage() {
  const prerenderUser = usePrerenderUser();
  const navigate = useNavigate();

  const trackingCodeInstalled = prerenderUser.trackingCodeInstalled;

  const { track } = useEvent();

  const [days, setTimeFilterValueInDays] = React.useState(7);

  const isMobile = useIsMobileView();

  const displayTeamMembersBanner = React.useMemo(() => {
    if (!prerenderUser.trackingCodeInstalledDate) return true;

    const daysFromTrackingCodeInstalled = Math.floor(
      (new Date() - new Date(prerenderUser.trackingCodeInstalledDate)) / (1000 * 60 * 60 * 24)
    );

    return daysFromTrackingCodeInstalled < 14;
  }, [prerenderUser.trackingCodeInstalledDate]);

  return (
    <AdminTemplate metaTags={META_TAGS}>
      <Content>
        <Flex gap={16} vertical={true} style={{ marginBottom: '16px' }}>
          <Flex vertical={true} style={{ marginBottom: '16px' }}>
            <Flex justify={'space-between'} align={'center'}>
              <Title level={2} style={{ marginBottom: 8 }}>
                Dashboard
              </Title>
              <Dropdown
                disabled={!trackingCodeInstalled}
                menu={{
                  defaultSelectedKeys: [`${days}`],
                  selectable: true,
                  items,
                  onSelect: ({ key }) => {
                    track('Dashboard Timeframe Selected', {
                      time_selected: 1 ? `${key} day` : `${key} days`,
                      subscription_plan: prerenderUser.planName,
                    });
                    setTimeFilterValueInDays(Number(key));
                  },
                }}
                placement="bottom"
                arrow
              >
                <Button disabled={!trackingCodeInstalled}>
                  <Space>
                    {days !== 1 ? `Last ${days} days` : `Last ${days} day`}
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </Flex>
            <Text type="secondary">Track, manage and forecast your Prerender activity and usage.</Text>
          </Flex>

          {/* not removed for now to switch back to the old onboarding */}
          <HighlightedFeaturesBanners>
            {displayTeamMembersBanner && prerenderUser.role !== USER_ROLES.GUEST && (
              <HighlightedFeatureBanner
                imageUrl="/assets/img/onboarding/team-members.svg"
                title={'Invite Your Team'}
                description="Share your dashboard with whoever needs it. Add unlimited users—no extra costs."
                CTA={
                  <Button
                    type="primary"
                    onClick={() => {
                      track('Invite Your Team banner clicked', { location: 'Dashboard' });
                      navigate('/security/team-members');
                    }}
                  >
                    Invite Team Members
                  </Button>
                }
              />
            )}

            {!trackingCodeInstalled && prerenderUser.role !== USER_ROLES.GUEST && (
              <HighlightedFeatureBanner
                imageUrl="/assets/img/onboarding/onboarding.svg"
                title="Your Quick Integration Guide"
                description="We call integration the server-side component that forwards requests made by crawlers, to our service."
                CTA={
                  <Button
                    type="primary"
                    onClick={() => {
                      track('Integration Started', { location: 'Dashboard' });
                      navigate('/integration-wizard');
                    }}
                  >
                    Integrate Prerender
                  </Button>
                }
              />
            )}
          </HighlightedFeaturesBanners>
          <Flex justify={'space-between'} vertical={isMobile} gap={isMobile ? 10 : 0}>
            <DashboardUsageOverview days={days} />
            <DeliveredToCrawlerCharts trackingCodeInstalled={trackingCodeInstalled} days={days} />
          </Flex>
          <Flex justify={'space-between'}>
            <DeliveredByStatusCharts trackingCodeInstalled={trackingCodeInstalled} days={days} />
          </Flex>
          <Flex justify={'space-between'}>
            <CrawlerVisitsCharts trackingCodeInstalled={trackingCodeInstalled} days={days} />
          </Flex>
          <LatestVisitsAndPagesInfo />
        </Flex>
      </Content>
    </AdminTemplate>
  );
}

export default requireAuth(DashboardPage);
