// HOC
// a higher-order component is a function that takes a component and returns a new component.
// Higher order component for components that require login:

import React, { useEffect } from 'react';
import { connect, useStore } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { setTargetAfterSignin } from '../../actions/pageActions';
import { getPrerenderUserThrows } from '../../actions/prerenderUserActions';
import SpinningWheel from '../SpinningWheel';

// type IsAuthorizedFn = ({ role, email }: { role: string, email: string, featureFlags: string[], chargebeePlanId: string })=> boolean;

export default function requireAuth(ComposedComponent, isAuthorizedFn) {
  const RequireAuth = (props) => {
    const { kcInProgress, kcError, kcLoggedIn, hasSession, getPrerenderUserThrows: doGetPrerenderUserThrows } = props;

    const navigate = useNavigate();
    const store = useStore();

    useEffect(() => {
      if (kcLoggedIn && !hasSession) {
        doGetPrerenderUserThrows()
          .then(() => {
            const { token, showRegistrationSteps } = store.getState().prerenderUser;
            if (showRegistrationSteps) {
              navigate('/registration');
            } else {
              window.saveJourney(token);
            }
          })
          .catch((e) => {
            console.error('Unhandled auth error', e);
            navigate('/error-uncaught');
          });
      }
    }, [kcLoggedIn, hasSession, doGetPrerenderUserThrows, navigate, store]);

    const inAnyProgress = kcInProgress; // || prerenderUserInProgress;

    if (inAnyProgress) return <SpinningWheel />;

    if (kcError) return <h3>{kcError}</h3>;

    if (!kcLoggedIn || !hasSession) return <SpinningWheel />;

    if (isAuthorizedFn) {
      const { role, email, featureFlags, chargebeePlanId } = props;
      if (!isAuthorizedFn({ featureFlags, role, email, chargebeePlanId })) return <Navigate to="/404" />;
    }

    return <ComposedComponent {...props} />;
  };

  function mapStateToProps(state) {
    return {
      kcInProgress: state.keycloak.inProgress,
      kcError: state.keycloak.error,
      kcLoggedIn: state.keycloak.loggedIn,
      hasSession: state.prerenderUser.hasSession,
      prerenderUserInProgress: state.prerenderUser.inProgress,
      featureFlags: state.prerenderUser.features,
      role: state.prerenderUser.role,
      email: state.prerenderUser.email,
      chargebeePlanId: state.prerenderUser.chargebeePlanId,
    };
  }

  // provide access to selected actions:
  function mapDispatchToProps(dispatch) {
    return bindActionCreators({ setTargetAfterSignin, getPrerenderUserThrows }, dispatch);
  }

  return connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
}
