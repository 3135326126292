import React from 'react';
import MetaTags from '../components/MetaTags';
import NavigationTopbar from './NavigationTopbar';
import Sidebar from './SideBar';
import { Layout } from 'antd';
import UpgradePlanAlert from '../features/upgrade-to-pro-alert/UpgradePlanAlert';
// import UsageLimitApproachAlert from '../features/usage-limit-approach-alert/UsageLimitApproachAlert';
import './AdminTemplate.css';

const AdminTemplate = ({ metaTags, children }) => {
  return (
    <Layout hasSider>
      <MetaTags tags={metaTags} />
      <Sidebar />
      <Layout className="site-layout">
        <NavigationTopbar />
        {<UpgradePlanAlert />}
        <main
          className="main-content"
          style={{ display: 'flex', flexDirection: 'column', minHeight: 'calc(100vh - 65px)', padding: 24 }}
        >
          {/* <UsageLimitApproachAlert /> */}
          {children}
        </main>
      </Layout>
    </Layout>
  );
};

export default AdminTemplate;
