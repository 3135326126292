import React from 'react';
import { Tag, Flex, Typography, Space, Button } from 'antd';
import { isEnterprise } from '@prerender/billing-shared';

import UserDropdownMenu from '../components/UserDropdownMenu';
import { HelpSection } from '../features/CustomerSupport';
import { usePrerenderUser } from '../hooks/usePrerenderUser';
import { DollarOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useEvent } from '../features/events/hooks/useEvent';
import { AppUserRoles } from '../features/teams';

const formatSecondRow = (user) => {
  const hasName = user.firstName || user.lastName;
  const hasCompanyName = !!user.companyName && user.companyName !== user.email;

  if (hasName && hasCompanyName) {
    return (
      <div>
        {user.firstName} {user.lastName} ({user.companyName}) <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </div>
    );
  } else if (hasName && !hasCompanyName) {
    return (
      <div>
        {user.firstName} {user.lastName} <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </div>
    );
  } else if (!hasName && hasCompanyName) {
    return (
      <div>
        ({user.companyName}) <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </div>
    );
  } else if (!hasName && !hasCompanyName) {
    return (
      <div>
        <Tag color="success">{AppUserRoles[user.role]}</Tag>
      </div>
    );
  }

  return undefined;
};

const NavigationTopbar = () => {
  const user = usePrerenderUser();
  const eventsTrackingData = {
    userId: user.id,
    userEmail: user.email,
    companyId: user.companyId,
    companyName: user.companyName,
  };
  const navigate = useNavigate();
  const { track } = useEvent();
  const isEnterpriseUser = isEnterprise(user?.chargebeePlanId);

  const onReferralButtonClick = () => {
    track('Refer and Earn button clicked', { subscription_plan: user.chargebeePlanId });
    navigate('/billing/refer-and-earn');
  };

  return (
    <nav className="navbar navbar-expand-md navbar-light d-none d-md-flex" id="topbar">
      <div className="container-fluid">
        <div className="w-100 navbar-user d-flex justify-content-between">
          <div className="d-flex align-items-center" style={{ paddingLeft: 24 }}>
            {!isEnterpriseUser && (
              <Button
                onClick={onReferralButtonClick}
                type="primary"
                icon={<DollarOutlined style={{ fontSize: 18 }} />}
                size="large"
              >
                Refer & Earn
              </Button>
            )}
          </div>
          <Flex gap="middle">
            <HelpSection
              contactCSMVisible={isEnterprise(user.chargebeePlanId)}
              eventsTrackingData={eventsTrackingData}
            />
            <Space>
              <div>
                <Typography.Text>{user.email}</Typography.Text>
                {formatSecondRow(user)}
              </div>
              {user.isCancelled && <Tag color="error">Cancelled</Tag>}
              <UserDropdownMenu />
            </Space>
          </Flex>
        </div>
      </div>
    </nav>
  );
};

export default NavigationTopbar;
