import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import { Spin } from 'antd';
import { usePaymentChargebeeInfo } from '../hooks/usePaymentChargebeeInfo';
import { AddonBillingType } from '@prerender/billing-shared';
import { InviteTeamMember } from '../../teams';
import { useUserRole } from '../../../hooks/usePrerenderUser';

const ChangePackageConfirmationModal = ({ isPending, pendingNewPackage, onClose, onConfirmPackageChange }) => {
  const { isPaymentInfoValid, isLoadingIsPaymentInfoValid, onChangeBillingAddress, onChangePaymentMethod } =
    usePaymentChargebeeInfo();

  const userRole = useUserRole();

  const isSubmitButtonEnabled = useMemo(() => {
    return (
      isPaymentInfoValid?.data?.hasPaymentMethod === true &&
      isPaymentInfoValid?.data?.isAddressValid === true &&
      isLoadingIsPaymentInfoValid === false
    );
  }, [isPaymentInfoValid, isLoadingIsPaymentInfoValid]);

  return (
    <>
      <h3>
        {!pendingNewPackage.isActive ? 'Package Subscription Confirmation' : 'Package Unsubscription Confirmation'}
      </h3>
      <div style={{ maxWidth: '600px', fontSize: '16px' }} className="mt-4">
        <p>
          {pendingNewPackage.billingType === AddonBillingType.OneTime
            ? `You’re about to purchase the ${pendingNewPackage.name} package. This will add the services to your account.`
            : !pendingNewPackage.isActive
            ? `You're about to subscribe to the ${pendingNewPackage.name} package. This will add the services to your account.`
            : 'Are you sure you want to unsubscribe from this package? It will remove the services from your account.'}
        </p>
        <p>
          This is often something that the billing team takes care of. If you aren’t sure how to proceed, you can invite
          coworker.
        </p>
        <InviteTeamMember
          source="Change Package Confirmation Modal"
          style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}
          selectRoles={true}
          userRole={userRole}
        />
        {!pendingNewPackage.isActive && (
          <div>
            <div className="mt-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="card-text fw-light d-flex align-items-center mb-0">Payment Method</p>
                {!isLoadingIsPaymentInfoValid && (
                  <>
                    {!isPaymentInfoValid?.data?.hasPaymentMethod ? (
                      <ExclamationCircleOutlined style={{ color: '#d16759' }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: '#6FC49D' }} />
                    )}
                  </>
                )}
                {isLoadingIsPaymentInfoValid ? <Spin /> : null}
              </div>
              {!isLoadingIsPaymentInfoValid && (
                <div className="rounded border p-3 d-flex justify-content-between align-items-center">
                  <div>
                    {!isPaymentInfoValid?.data?.hasPaymentMethod ? (
                      <p className="card-text fw-light text-danger">You need to add a payment method to proceed.</p>
                    ) : (
                      <p className="card-text fw-light">Your payment method is set.</p>
                    )}
                  </div>
                  <button onClick={onChangePaymentMethod} className="btn btn-sm btn-outline-primary ">
                    {!isPaymentInfoValid?.data?.hasPaymentMethod ? 'Add' : 'Change'}
                  </button>
                </div>
              )}
            </div>
            <div className="mt-5">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <p className="card-text fw-light d-flex align-items-center mb-0">Billing Address</p>
                {!isLoadingIsPaymentInfoValid && (
                  <>
                    {!isPaymentInfoValid?.data?.isAddressValid ? (
                      <ExclamationCircleOutlined style={{ color: '#d16759' }} />
                    ) : (
                      <CheckCircleOutlined style={{ color: '#6FC49D' }} />
                    )}
                  </>
                )}
                {isLoadingIsPaymentInfoValid ? <Spin /> : null}
              </div>
              {!isLoadingIsPaymentInfoValid && (
                <div className="rounded border p-3 d-flex justify-content-between align-items-center">
                  <div>
                    {!isPaymentInfoValid?.data?.isAddressValid ? (
                      <p className="card-text fw-light text-danger">You need to add a billing address to proceed.</p>
                    ) : (
                      <p className="card-text fw-light">A billing address is set.</p>
                    )}
                  </div>
                  <button onClick={onChangeBillingAddress} className="btn btn-sm btn-outline-primary ">
                    {!isPaymentInfoValid?.data?.isAddressValid ? 'Add' : 'Change'}
                  </button>
                </div>
              )}
            </div>
            <div className="mt-5 d-flex justify-content-between font-weight-bold">
              <span>Price</span> <span>{`$${pendingNewPackage.costInCents / 100}`}</span>
            </div>
          </div>
        )}
        <hr />
        <div className="mt-5 d-flex justify-content-between pb-5">
          <button style={{ fontSize: '16px' }} onClick={onClose} className="btn btn-outline-primary">
            Cancel
          </button>
          <button
            onClick={() =>
              onConfirmPackageChange(pendingNewPackage.chargeBeeAddonId, pendingNewPackage.isActive ? false : true)
            }
            style={{ backgroundColor: '#6FC49D', color: 'white', fontSize: '16px' }}
            className="ml-2 btn"
            disabled={isPending === true || isSubmitButtonEnabled !== true}
          >
            {!isPending &&
              (pendingNewPackage.isActive
                ? 'Unsubscribe'
                : pendingNewPackage.billingType === AddonBillingType.OneTime
                ? 'Buy'
                : 'Subscribe')}

            {isPending && <Spin />}
          </button>
        </div>
      </div>
    </>
  );
};

export default ChangePackageConfirmationModal;
