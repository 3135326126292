import React, { useState, Fragment } from 'react';
import { DesktopOutlined, MobileOutlined } from '@ant-design/icons';

import { Card } from '../../../components/CssFrameworkComponents';
import SearchBarV2 from '../../../components/SearchBarV2';
import { CheckListOptions } from '../../../components/PageElementsLib';
import { getAgeString, getDateTimeTooltip } from '../../../assets/time';
import { Tooltip, Button, Row, Col, Typography, Table } from 'antd';
import { DeletePageButton } from '../../../components/PagesActionButtons';
import SeoScoreComponent from '../../../components/pages/SeoScoreComponent';
import './cacheManagerTable.css';
import { isFreePlan } from '../../../chargebee/chargebee';
import { Pagination } from '../../../components/PaginationLib';
import { useEvent } from '../../events/hooks/useEvent';
import { isNull, omitBy } from 'lodash';
import { createFilterEventProperties } from '../../../utils/createFilterEventProperties';
import ExportButton from '../../../components/ExportButton';
import SeoScoreStatusComponent from '../../../components/pages/SeoScoreStatusComponent';
import { DomainSearchDropdown } from '../../../components/DomainSearchDropdown';
import { useShowNewSeoScore } from '../../../hooks/useShowNewSeoScore';
import ButtonWithRole from '../../../components/ButtonWithRole';
import USER_ROLES from '../../auth/userRoles';

const { Column } = Table;
const { Link } = Typography;

const SHOW_NEVER = true;

const sourcesList = [
  { name: 'site', label: 'Site', tooltip: 'Added through our dashboard or website' },
  { name: 'recache_api', label: 'Recache API', tooltip: 'Added via our API for recaching' },
  { name: 'cdn', label: 'CDN', tooltip: 'Processed by service.prerender.io CDN' },
  { name: 'sitemap', label: 'Sitemap', tooltip: 'Sourced from a sitemap service' },
  { name: 'recache', label: 'Recache', tooltip: 'Auto-recache for removed URLs' },
  { name: 'add_url_api', label: 'API', tooltip: 'Added via our API' },
  { name: '', label: '---' },
];

const seoScoreStatuses = [
  { value: 'improvement', text: 'Improvement' },
  { value: 'passed', text: 'Passed' },
  { value: 'unknown', text: 'Unknown' },
];

const sourceToReadable = (sourceName: string) => {
  let source = sourcesList.find((s) => s.name === sourceName);
  if (!source) source = { label: '---' };

  return source;
};

const cacheStatesList = [
  { name: 'Cached', label: 'Cached', tooltip: 'The URL has been cached' },
  { name: 'Caching...', label: 'Caching...', tooltip: 'The URL is currently being cached' },
  { name: 'Recaching...', label: 'Recaching...', tooltip: 'The URL is currently being recached' },
  { name: '', label: '---' },
];

const mapCacheState = (stateName) => {
  let source = cacheStatesList.find((s) => s.name === stateName);
  if (!source) source = { label: '---' };

  return source;
};

const mapToTrackingProperties = (filterResult) => {
  const filtersWithoutNull = omitBy(filterResult, isNull);
  return {
    selected_device_filters: filtersWithoutNull?.adaptive_type,
    selected_state_filters: filtersWithoutNull?.cache_state,
    selected_source_filters: filtersWithoutNull?.source,
  };
};

const CacheManagerTable = ({
  prerenderUser,
  cachedPages,
  cm,
  state,
  props,
  setState,
  didReceiveAction,
  performQuerySearch,
  performDomainSearch,
  performQuerySearchAnyway,
  onSearchConditionChanged,
  performSearch,
  reloadData,
  clearSearch,
  domains,
}) => {
  const { selectedUrls, showCheckListOptions, customMessage, querySearchInProgress, initialSearchQuery, activeTab } =
    state;

  const [filteredInfo, setFilteredInfo] = useState({});
  const { track } = useEvent();
  const userRole = prerenderUser.role;

  const clearFilters = () => {
    setFilteredInfo({});
    clearSearch();
  };

  const selectAll = () => {
    setState({
      selectedUrls: state.selectedUrls.length === props.cachedPages.pages.length ? [] : props.cachedPages.pages,
    });
  };

  const getCacheStateView = (cache_state: string) => {
    var cacheState = mapCacheState(cache_state);
    return (
      <Tooltip title={cacheState.tooltip} placement="top">
        {cacheState.label}
      </Tooltip>
    );
  };

  const getSourceView = (source: string) => {
    const sourceData = sourceToReadable(source);
    return (
      <Tooltip title={sourceData.tooltip} placement="top">
        {sourceData.label}
      </Tooltip>
    );
  };

  const getFirstSeenView = (created_at: string) => {
    if (created_at) {
      return (
        <Tooltip
          title={
            <span>
              <b>Page First Rendered At</b> <br /> {new Date(created_at).toISOString()}
            </span>
          }
          placement="top"
        >
          {new Date(created_at).toLocaleDateString()}
        </Tooltip>
      );
    } else {
      return '---';
    }
  };

  const handleTableChange = (pagination, filters, sorter, details) => {
    const selectedFiltersToTrack = createFilterEventProperties(filters, filteredInfo, mapToTrackingProperties);
    setFilteredInfo(filters);
    didReceiveAction({
      action: 'applyFilter',
      payload: {
        action: details.action,
        //page: pagination.position ? pagination.position : undefined,
        filter: filters ? filters : undefined,
        sort: sorter.order
          ? {
              name: sorter.columnKey,
              asc: sorter.order === 'ascend',
            }
          : undefined,
      },
    });
    if (details.action === 'filter' && Object.keys(selectedFiltersToTrack).length !== 0) {
      track(`Cache URL List Filtered: ${Object.keys(selectedFiltersToTrack)}`, {
        subscription_plan: prerenderUser.chargebeePlanId,
        ...selectedFiltersToTrack,
      });
    }
  };

  const formatPageNumbers = (stats) => {
    const from = cachedPages.params.page * cachedPages.params.pageSize + 1;
    const to = cachedPages.params.page * stats.amtpp + stats.amt;

    return from <= to ? `Results ${from} - ${to}` : ''; // This can happen when stats.amt === 0, so the last page is empty
  };

  interface DataType {
    domain: string;
    url: string;
    cache_state: any;
    user_id: number;
    last_refresh: Date;
    requested_recache_at: Date;
    adaptive_type: string;
    seo_score: number;
    created_at: Date;
    id: number;
    source: string;
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);

      setState({
        selectedUrls: selectedRows,
        customMessage: null,
      });
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const paidPlan = !isFreePlan(prerenderUser.plan);

  const showNewScore = useShowNewSeoScore();

  return (
    <Fragment>
      {/* Cached pages table */}
      <div className="row scrollbar-table">
        <Card>
          <div className="card-header pr-3">
            <Row style={{ marginLeft: '-15px', marginRight: '-15px' }}>
              {domains.domains.length > 1 && paidPlan && (
                <Col flex="200px">
                  <DomainSearchDropdown
                    selectedDomain={cachedPages.params.domain}
                    domains={domains.domains}
                    performDomainSearch={performDomainSearch}
                  />
                </Col>
              )}
              <Col flex="auto" offset={domains.domains.length > 1 && paidPlan ? 1 : 0}>
                <SearchBarV2
                  id={state.searchBarId}
                  initialValue={initialSearchQuery}
                  onTypingStart={() => setState({ customMessage: null })}
                  onTypingEnd={(query) => performQuerySearch(query)}
                  onEnterPressed={(query) => performQuerySearchAnyway(query)}
                  onSearchConditionChanged={(condition) => onSearchConditionChanged(condition)}
                  disabled={selectedUrls.length > 0}
                  placeholder="Please enter at least 3 characters to start your search"
                />
              </Col>
              <Col flex="210px" className="m-auto">
                <div>
                  <Button className="ml-2" disabled={cachedPages.inProgress} onClick={() => reloadData()} size="small">
                    <i className="fe fe-rotate-cw"></i>
                  </Button>
                  <Button
                    className=" ml-2"
                    disabled={cachedPages.inProgress}
                    onClick={() => clearFilters()}
                    size="small"
                  >
                    Clear filters
                  </Button>
                  <ExportButton
                    isFreePlan={!paidPlan}
                    hasSelectedUrls={selectedUrls.length > 0}
                    onClick={() => didReceiveAction({ action: 'openExportModal', payload: [] })}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <Table
            className="table card-table table-sm table-hover table-nowrap"
            rowSelection={{
              ...rowSelection,
              type: 'checkbox',
              getCheckboxProps: (_record) => ({
                disabled: userRole === USER_ROLES.BILLING_MANAGER || userRole === USER_ROLES.GUEST,
              }),
            }}
            rowKey="id"
            loading={cachedPages.inProgress || prerenderUser.inProgress || querySearchInProgress}
            dataSource={cachedPages.pages}
            onChange={handleTableChange}
            pagination={false}
            scroll={{ x: 400 }}
          >
            <Column
              title="Content Age"
              dataIndex="last_refresh"
              key="last_refresh"
              align="center"
              width="5%"
              sorter="true"
              defaultSortOrder="descend"
              render={(last_refresh: Date) => (
                <span data-tip={last_refresh && getDateTimeTooltip(last_refresh)}>
                  {getAgeString(new Date(last_refresh), 'ago', 'long', SHOW_NEVER)}
                </span>
              )}
            />
            <Column
              title="URL"
              dataIndex="url"
              key="url"
              render={(url: string) => (
                <div className="d-inline-block text-truncate url-shortener">
                  <Link href={url} target="_blank" rel="noreferrer">
                    {url}
                  </Link>
                </div>
              )}
            />
            <Column
              title="Device"
              dataIndex="adaptive_type"
              key="adaptive_type"
              align="center"
              width="5%"
              filteredValue={filteredInfo.adaptive_type || null}
              filters={[
                {
                  text: 'Desktop',
                  value: 'desktop',
                },
                {
                  text: 'Mobile',
                  value: 'mobile',
                },
              ]}
              render={(adaptive_type: string) => (
                <Tooltip
                  title={`Rendering was optimized for [${adaptive_type === 'mobile' ? 'Mobile' : 'Desktop'}] crawlers`}
                  placement="top"
                >
                  {adaptive_type === 'mobile' ? <MobileOutlined /> : <DesktopOutlined />}
                </Tooltip>
              )}
            />

            {showNewScore ? (
              /* Take into consideration the seo_score_status for multiple sorting*/
              <Column
                title="SEO Score"
                dataIndex="seo_score_status"
                key="seo_score_status"
                align="center"
                width="5%"
                sorter="true"
                filteredValue={filteredInfo.seo_score_status || null}
                filters={seoScoreStatuses}
                render={(seo_score_status, record) => (
                  <Button
                    type="ghost"
                    size="small"
                    onClick={() => {
                      track('SEO Score Clicked', { subscription_plan: prerenderUser.chargebeePlanId });
                      didReceiveAction({ action: 'openSeoScoreStatusModal', payload: record });
                    }}
                  >
                    <SeoScoreStatusComponent status={seo_score_status} />
                  </Button>
                )}
              />
            ) : (
              <Column
                title="SEO Score"
                dataIndex="seo_score"
                key="seo_score"
                align="center"
                width="5%"
                sorter="true"
                filteredValue={filteredInfo.seo_score || null}
                render={(seo_score, record) => (
                  <Button
                    type="ghost"
                    size="small"
                    onClick={() => {
                      track('SEO Score Clicked', { subscription_plan: prerenderUser.chargebeePlanId });
                      didReceiveAction({ action: 'openSeoScoreModal', payload: record });
                    }}
                  >
                    <SeoScoreComponent score={seo_score} />
                  </Button>
                )}
              />
            )}
            <Column
              title="State"
              dataIndex="cache_state"
              key="cache_state"
              align="center"
              width="5%"
              sorter="true"
              filteredValue={filteredInfo.cache_state || null}
              filters={cacheStatesList
                .filter((s) => s.name)
                .map((s) => ({
                  text: s.label,
                  value: s.name,
                }))}
              render={(cache_state: string) => getCacheStateView(cache_state)}
            />

            <Column
              title="First Seen"
              dataIndex="created_at"
              key="created_at"
              align="center"
              width="5%"
              sorter="true"
              render={(created_at: string) => getFirstSeenView(created_at)}
            />
            <Column
              title="Source"
              dataIndex="source"
              key="source"
              align="center"
              width="5%"
              sorter="true"
              filteredValue={filteredInfo.source || null}
              filters={sourcesList
                .filter((s) => s.name)
                .map((s) => ({
                  text: s.label,
                  value: s.name,
                }))}
              render={(cache_state: string) => getSourceView(cache_state)}
            />
            <Column
              title="Last crawled at"
              dataIndex="last_delivery_at"
              key="last_delivery_at"
              align="center"
              width="5%"
              sorter="true"
              render={(last_delivery_at) => getAgeString(new Date(last_delivery_at), 'ago', 'long', SHOW_NEVER)}
            />
            <Column
              title=""
              key="action"
              align="right"
              width="5%"
              render={(_: any, record: DataType) => (
                <>
                  <div className="d-none d-lg-block">
                    <ButtonWithRole
                      disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                      size="small"
                      className="ml-2"
                      disabled={cachedPages.inProgress}
                      onClick={() => didReceiveAction({ action: 'requestRecache', payload: [record] })}
                    >
                      Recache
                    </ButtonWithRole>

                    <DeletePageButton
                      disabled={cachedPages.inProgress}
                      onClick={() => didReceiveAction({ action: 'deleteUrls', payload: [record] })}
                      tooltip={cm.table.actions.delete}
                    />
                  </div>
                  <div className="d-lg-none">
                    {' '}
                    {/* Show this div on screens smallers than large*/}
                    <ButtonWithRole
                      disabledFor={[USER_ROLES.BILLING_MANAGER, USER_ROLES.GUEST]}
                      onClick={() => didReceiveAction({ action: 'openEditUrlModal', payload: [record] })}
                      className="btn btn-link p-0"
                    >
                      <i className="fe fe-more-vertical"></i>
                    </ButtonWithRole>
                  </div>
                </>
              )}
            />
          </Table>
          <Pagination
            onPageChange={(action) => didReceiveAction(action)}
            stats={{
              total: cachedPages.params.query ? cachedPages.stats.total : prerenderUser.numPagesCached,
              amt: cachedPages.stats.amt,
              pages: cachedPages.stats.pages,
              page: cachedPages.params.page,
              amtpp: cachedPages.params.pageSize,
            }}
          ></Pagination>
        </Card>
      </div>
      <CheckListOptions
        show={showCheckListOptions}
        count={selectedUrls.length}
        name="URL"
        actions={[
          { name: 'requestRecache', text: 'Recache' },
          { name: 'deleteUrls', icon: 'trash-2' },
          { name: 'openEditUrlsModal', icon: 'more-vertical' },
        ]}
        onsubmit={(action) => didReceiveAction({ action, payload: selectedUrls })}
        isActive={!cachedPages.inProgress}
        onclose={() => setState({ selectedUrls: [] })}
      />
    </Fragment>
  );
};

export default CacheManagerTable;
